import styled from 'styled-components';

const Inner = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  &.loading {
    justify-content: center;
  }
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    flex-direction: column-reverse;
    padding: 0 50px;
  }
  > div {
    width: calc(50% - (0.5 * 30px));
    @media only screen and (max-width: ${props =>
        props.theme.responsive.medium}) {
      width: 100%;
    }
    &:last-child {
    }
  }
`;

export default Inner;
