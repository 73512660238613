import React, { Component } from 'react';

// Components
import { Wrapper, Inner, Left, Right } from './files';
import Loading from '../../loading';

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewData: [],
      isLoading: true
    };
  }

  componentDidMount() {
    const TA_KEY = process.env.GATSBY_TRIP_ADVISOR_API;
    const self = this;
    const proxyurl = 'https://thingproxy.freeboard.io/fetch/';
    const url = `https://api.tripadvisor.com/api/partner/2.0/location/589097?key=${TA_KEY}`;
    fetch(proxyurl + url)
      .then((data) => data.json())
      .then((data) => {
        self.setState({
          reviewData: data,
          isLoading: false
        });
      });
  }

  render() {
    const { isLoading, reviewData } = this.state;
    return (
      <Wrapper>
        <Inner className={isLoading ? 'loading' : ''}>
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              <Left tripAdvisorData={reviewData} />
              <Right tripAdvisorData={reviewData} />
            </>
          )}
        </Inner>
      </Wrapper>
    );
  }
}
export default Reviews;
