import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 50%;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin: 0 auto 2em;
    width: 95%;
  }
`;

const ReviewSection = styled.div`
  li {
    margin: 30px 0;
  }
  h2 {
    color: ${props => props.theme.colors.text.dark};
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      padding-bottom: 15px;
      font-size: 22px;
      line-height: 25px;
    }
  }
  h4 {
    color: ${props => props.theme.colors.text.dark};
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    margin: 30px 0 10px;
  }
  img {
    height: 15px;
  }
  p {
    margin: 0;
    text-align: justify;
  }
`;

const ReviewsRight = props => {
  const { tripAdvisorData } = props;
  return (
    <Wrapper>
      <ReviewSection>
        <h2>Most Recent Reviews</h2>
        <ul>
          {tripAdvisorData.reviews.map(i => {
            const truncateLength = 180;
            const reviewText = i.text.substring(0, truncateLength);
            return (
              <li key={i.id}>
                <h4>{i.title}</h4>
                <img
                  src={i.rating_image_url.replace(/^http:\/\//i, 'https://')}
                  alt={i.text}
                />
                <p>
                  {reviewText}
                  ...&nbsp;
                  <a
                    href={i.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`read more ${i.text}`}
                  >
                    read more
                  </a>
                </p>
              </li>
            );
          })}
        </ul>
      </ReviewSection>
    </Wrapper>
  );
};

export default ReviewsRight;
