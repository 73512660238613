import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import tripAdvisorLogo from '../../../../../assets/images/icons/tripAdvisorLogo.svg';

const Wrapper = styled.div`
  width: 50%;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    margin: 0 auto 2em;
    width: 95%;
  }
`;

const Top = styled.div`
  img {
    width: 125px;
  }
  h2 {
    color: ${props => props.theme.colors.text.dark};
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      padding-bottom: 15px;
      font-size: 22px;
      line-height: 25px;
    }
  }
  small {
    color: ${props => props.theme.colors.text.normal};
    text-decoration: underline;
  }
`;

const Middle = styled.div`
  padding: 30px 0 15px;
  margin: 10px 0px;
  h3 {
    color: ${props => props.theme.colors.text.dark};
    margin: 0;
  }
  p {
    color: #207b6c;
    font-size: 0.75em;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

const Bottom = styled.div`
  a {
    color: ${props => props.theme.colors.text.dark};
    position: relative;
    padding-bottom: 4px;
    text-decoration: none;
    line-height: 1em;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.05em;
    &:after {
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: width 150ms;
      background-color: ${props => props.theme.colors.text.dark};
    }
    &:hover {
      &:after {
        width: 0;
      }
    }
    &:first-child {
      margin-right: 15px;
    }
  }
  img {
    width: 200px;
    display: block;
    margin: 40px 0 10px;
  }
  small {
    color: ${props => props.theme.colors.text.normal};
  }
`;

const ReviewsLeft = props => {
  const { tripAdvisorData } = props;
  return (
    <Wrapper>
      <Top>
        <h2>TripAdvisor Rating</h2>
        <img
          src={tripAdvisorData.rating_image_url.replace(
            /^http:\/\//i,
            'https://'
          )}
          alt={tripAdvisorData.ranking_data.ranking_string}
        />
        <br />
        <small>
          based on&nbsp;
          {tripAdvisorData.num_reviews}
          &nbsp;traveller reviews
        </small>
      </Top>
      <Middle>
        <h3>TripAdvisor Ranking</h3>
        <p>{tripAdvisorData.ranking_data.ranking_string}</p>
      </Middle>
      <Bottom>
        <a
          href={tripAdvisorData.web_url}
          target="_blank"
          rel="noopener noreferrer"
          title={`More ${tripAdvisorData.name} Reviews`}
        >
          More Reviews
        </a>
        <a
          href={tripAdvisorData.write_review}
          target="_blank"
          rel="noopener noreferrer"
          title={`Write a review for ${tripAdvisorData.name}`}
        >
          Write Review
        </a>
        <img src={tripAdvisorLogo} alt="TripAdvisor Logo" />
        <small>© {moment().year()} TripAdvisor LLC</small>
      </Bottom>
    </Wrapper>
  );
};

export default ReviewsLeft;
